import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import React, { lazy, Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/styles/tailwind.css";
import { Backdrop, CircularProgress } from "@mui/material";

const ProtectedRoute = lazy(() => import('./utils/ProtectedRoute'));
const MainRoutes = lazy(() => import('./Routes'));
const Login = lazy(() => import('./pages/Login'));

function App() {
  let token = sessionStorage.getItem("token");
  
  return (
    <Suspense fallback={
      <Backdrop
        sx={{ color: '#ff6500', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    }>
      <Routes>
        <Route path="/login" element={token == null ? <Login /> : <Navigate to="/" />} />
        {/* <Route path="/confirm-password/:token/:id" element={token == null ? <ConfirmPassword /> : <Navigate to="/admin" />} /> */}
        <Route path="*" element={<ProtectedRoute><MainRoutes /></ProtectedRoute>} />
      </Routes>
      <ToastContainer autoClose={2000} />
    </Suspense>
  );
}

export default App;
