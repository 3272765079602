import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new QueryClient();
root.render(
  <ErrorBoundary fallback={<h1>Some thing went wrong</h1>}>
    <QueryClientProvider client={client}>
      <BrowserRouter>
        
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </ErrorBoundary>
);
